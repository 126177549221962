@import "~antd/dist/antd.css";

.hover:hover {
  cursor: pointer;
}


.filter-group {
  text-align: right;
  /* display: grid; */
  grid-template-columns: repeat(6, 1fr);
  justify-items: center;
  align-items: center;
  grid-gap: 10px;
}

.filter-group .form-group {
  width: 220px;
  display: inline-block;
  margin-left: 20px;
}

.filter-group .form-group .ant-picker-range {
  padding: 0.41rem 0.67rem;
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.ant-picker-input>input {
  font-size: 0.8125rem;
}

.ant-select-selector {
  display: block;
  width: 100%;
  padding: 0.20rem 0.75rem !important;
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.25rem !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.custom-select-ant .ant-select-selector {
  height: 36.17px !important;
}

.custom-datepicket-ant {
  height: 38.17px;
  border-radius: 5px;
  width: 100%;
}

.basic-ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.conversation-list {
  width: 40%;
}