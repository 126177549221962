/* Define what each icon button should look like */
.button {
  /* color: white; */
  /* display: inline-block; Inline elements with width and height. TL;DR they make the icon buttons stack from left-to-right instead of top-to-bottom */
  position: relative; /* All 'absolute'ly positioned elements are relative to this one */
  padding: 2px 5px; /* Add some padding so it looks nice */
}

/* Make the badge float in the top right corner of the button */
.button__badge {
  background-color: #fa3e3e;
  color: white;
  padding: 3px;
  font-size: 10px;
  position: absolute;
  top: -5px;
  right: -5px;
  width: 20px;
  height: 20px;
  text-align: center;
  border-radius: 30%;
}
